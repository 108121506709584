.dark-mode {
  background-color: #333; /* Dark background color */
  color: var(--title-color); /* Light text color */
}

.light-mode {
  background-color: #fff;
}

.dark-button {
  background-color: #CDC9FF;
  width: 35px;
  height: 24px;
  border: none;
  border-radius: 5px;
  color: #333;
}

.dark-button:hover {
  color: var(--title-color-dark);
}

.dark-sun {
  background-color: #CDC9FF; /* Same as the background color */
  color: #333;
}

.dark-moon {
  background-color: #CDC9FF;
}

@media screen and (max-width: 992px) {

}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 576px) {
}

@media screen and (max-width: 350px) {

}
