.home__container {
  row-gap: 2rem;
}


.home__content {
  grid-template-columns: 500px repeat(1, 1fr);
  column-gap: 15rem;
  align-items: center;
  margin-left: 9rem;
}

.home__data {
  width: 700px;
}

.home__social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
  margin-bottom: 6.5rem;
}

.home__social-icon {
  font-size: 1.25rem;
  color: var(--title-color);
}

.home__social-icon:hover {
  color: var(--title-color-dark);
}

:root {
  --big-font-size: 47px;
}

.home__title {
  font-size: var(--h1-font-size);
  display: flex;
  align-items: center;
  margin-bottom: var(--mb-2);
  column-gap: 3rem;
}

.home__hand {
  width: 38px;
  height: 38px;
  margin-left: 0.4rem;
}

.home__description {
  font-family: 'Spectral', sans-serif;
  font-size: var(--normal-font-size);
  margin-bottom: var(--mb-1);
}

.home__quote {
  font-size: var(--smaller-font-size);
  margin-bottom: var(--mb-1);
  width: 800px;
}

.home__subtitle {
  position: relative;
  font-size: var(--normal-font-size);
  padding-left: 5.4rem;
  font-weight: var(--font-normal);
  margin-bottom: var(--mb-1);
}

.home__subtitle::before {
  content: '';
  position: absolute;
  width: 70px;
  height: 1px;
  background-color: var(--text-color);
  left: 0;
  top: 1rem;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--small-font-size);
  height: 10vh; 
}

.home__img {
  background: url(../../assets/profile-pic.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 4px rgb(255 255 255 / 30%);
  order: 1;
  justify-self: center;
  width: 150px;
  height: 150px;
  margin-top: 1rem;
  animation: profile__animate 8s ease-in-out infinite 1s;
}

.dark-mode {
  color: #fff;

  .home__title,
  .home__subtitle,
  .home__description,
  .home__social-icon,
  .home__quote,
  .animated__link {
    color: #fff;
  }
  
}

.dark-mode .button--flex {
    background-color: #fff;
    color: #333;
}


:root,
html {
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  font-size-adjust: 100%;
  font-variant-ligatures: common-ligatures;
  font-feature-settings: "kern" 1, "liga" 1;
  font-kerning: normal;
  font-smooth: always;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
  -ms-tap-highlight-color: transparent;
  -o-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -o-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

*,
:before,
:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  -ms-box-sizing: inherit;
  -o-box-sizing: inherit;
  box-sizing: inherit;
}

@media (-webkit-min-device-pixel-ratio: 1.3),
  (min--moz-device-pixel-ratio: 1.3),
  (-moz-min-device-pixel-ratio: 1.3),
  (-ms-min-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 13/10),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 120dpi),
  (min-resolution: 1.3dppx) {
  :root {
    font-smoothing: subpixel-antialiased;
  }
}

/* body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  transform-style: preserve-3d;
} */

mark {
  position: relative;
  display: inline-block;
  background: none;
  /* Responsive typography */
  line-height: normal;
  margin: 0;
  padding: 0;
  outline: none;
  color: #000;
  color: rgba(0, 0, 0, 0.9);
  text-decoration: none;
  white-space: nowrap;
}


span {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: 0 0.1em;
}

svg {
  animation: draw 1.5s forwards;
  position: absolute;
  top: -10%;
  left: -10%;
  right: -10%;
  bottom: -10%;
  z-index: -1;
  overflow: hidden;
  margin: auto;
  padding: 0;
  width: 100%;
  height: 100%;
  fill: none;
  stroke-width: 54px;
  /* height from "svg" x 1.125 */
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  transition: stroke-dashoffset 800ms ease-in-out;
  will-change: stroke-dashoffset;
  pointer-events: none;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

svg:first-of-type {
  width: 105.5%;
  height: 104%;
  transform: skewX(-17deg);
}

svg:last-of-type {
  width: 104%;
  transform: skewX(-12deg);
}

@keyframes profile__animate {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }

  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }

  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}


/* breakpoints */
@media screen and (max-width: 992px) {
  .home__content {
    grid-template-columns: 200px repeat(2, 1fr);
    column-gap: 10rem;
    column-gap: 33rem;
  }

  .home__hand {
    width: 26px;
    height: 26px;
  }

  .home__subtitle {
    padding-left: 3.75rem;
    margin-bottom: var(--mb-1);
  }

  .home__subtitle::before {
    width: 42px;
    top: 0.8rem;

  }

  .home__description {
    max-width: initial;
    margin-bottom: var(--mb-1-5);    
  }

  .home__img {
    margin-top: 2rem;
    width: 180px;
    height: 180px;
    box-shadow: inset 0 0 0 4px rgb(255 255 255 / 30%);
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
  }

}

@media screen and (max-width: 768px){ 
  .home__content {
    grid-template-columns: 0.5fr 3fr;
    padding-top: 3.5rem;
  }

  .home__img {
    order: initial;
    justify-self: initial;
  }

  .home__data {
    grid-column: 1/3;
  }

  .home__social {
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
    margin-bottom: 5rem;
  }

  .home__img {
    box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
    width: 200px;
    height: 200px;
  }
}

@media screen and (max-width: 350px){ 
  .home__img {
    width: 180px;
    height: 180px;
  }

  .home__hand {
    width: 22px;
    height: 22px;
  }

  
}